<template>
    <div id="CapexPlanningLeasing">
        <v-container fluid app>
            <ZnapHotGrid
                :options="options"
                :dropdownOptions="dropdownOptions"
                :tableName="tableName"
                :tableIcon="tableIcon"
                :tableDescription="tableDescription"
            >
            </ZnapHotGrid>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapHotGrid from '@/components/znap/HotGridLeasing.vue'

export default {
    name:'CapexPlanningLeasing',

    components:{
        ZnapHotGrid
    },

    data: () => ({
        tableName: 'Planejamento de leasing',
        tableIcon: 'mdi-finance',
        tableDescription: 'Forneça uma descrição para esta tabela',
        options: [
            {
                column: 'id_event',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Evento',
                required: true,
                filterable: false,
                endpoint: [ Vue.prototype.$ipEvent, 'event' ]
            },
            {
                column: 'id_purpose',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Propósito',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose' ]
            },
            {
                column: 'id_cost_center',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Centro de custo',
                required: true,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-cost-center' ]
            }
        ],

        dropdownOptions: [
            {
                column: 'id_asset_class',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Classe do ativo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCapexPlanning, 'asset-class' ]
            },
            {
                column: 'id_account_group',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta gerencial',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipOrganization, 'purpose-account' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_purpose',
                        operator: '=',
                        value: null
                    }
                ]
            },
            {
                column: 'id_chart_account',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Conta contábil',
                required: false,
                filterable: true,
                endpoint: [ Vue.prototype.$ipAccount, 'chart-account-group' ],
                conditions: [
                    {
                        AndOr: 'AND',
                        column: 'id_account_group',
                        operator: 'IN',
                        value: null
                    },
                    {
                        AndOr: 'AND',
                        column: 'id_chart_account_group_version',
                        operator: '=',
                        value: null
                    }
                ]
            },
            {
                column: 'id_asset_project',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Nome do projeto/produto',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCapexPlanning, 'asset-project' ]
            },
            {
                column: 'id_asset',
                is: 'ComboBox',
                items: [],
                multiple: false,
                label: 'Ativo',
                required: false,
                filterable: false,
                endpoint: [ Vue.prototype.$ipCapexPlanning, 'asset' ]
            },
        ]
    }),
}
</script>

<style scoped>
</style>